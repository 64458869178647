.colors-editor {
  h4 {
    margin-bottom: 0;
  }

  .color-item {
    display: block;
    width: 38px;
    height: 38px;
    border: 0;
    cursor: pointer;
    background-size: contain;
    background-color: transparent;
    border-radius: 4px;
    padding: 1px;
    position: relative;

    .color-value {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 4px;

      &.transparent {
        position: relative;
        overflow: hidden;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 2.5px;
          height: 49px;
          background-color: #cf5d60;
          transform: rotate(45deg) translate(-17px, 6px);
        }
      }
    }

    &.inactive {
      border: 1px solid #a8a8a8;
    }

    &.active {
      border: 1px solid #6f0bea;
    }

    &:hover {
      .color-remove {
        display: block;
      }
    }

    .color-remove {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      opacity: 0.6;
      transition: opacity 0.3s linear;
      display: none;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.eye-dropper {
  > div {
    margin: 0 auto;
  }
}


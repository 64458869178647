@import '../../../../style/variables.less';

.sidebar .elements {
  display: flex;
  flex-direction: column;
  height: 100%;
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .layer-control {
    margin-top: 12px;
  }
  .elements-btns .ant-btn {
    width: 106.6px;
    padding: 3px 6px;
  }
  .ant-btn-group {
    .ant-btn-primary {
      &:first-child:not(:last-child) {
        border-right-color: @default-border-color;
      }
      &:not(:first-child):not(:last-child) {
        border-right-color: @default-border-color;
        border-left-color: @default-border-color;
      }
      &:last-child:not(:first-child),
      & + & {
        border-left-color: @default-border-color;
      }
    }
  }
  .ant-collapse .ant-collapse-item {
    .ant-collapse-header {
      padding: 12px 0;
    }
    .ant-collapse-content .ant-collapse-content-box {
      padding: 0 0 12px 0;
    }
  }
  .elements-tabs {
    height: 100%;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
    .item-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 8px 0;
      &:first-child {
        margin: 0 0 8px 0;
      }
    }
    .tab-all-items {
      display: flex;
      column-gap: 6px;
      .element-item,
      .element-item-img {
        width: 97px;
        height: 97px;
      }
    }
    .horizontal-scroll {
      margin-bottom: 14px;
      .tab-item {
        cursor: pointer;
        color: @icon-dark-grey-color;
        font-weight: 600;
        line-height: 1.4em;
        background-color: rgba(189, 189, 189, 0.1);
        padding: 10px 12px;
        border-radius: 4px;
        transition: color, background-color 0.2s ease-in-out;
        & ~ .tab-item {
          margin-left: 4px;
        }
        &.active {
          color: #ffffff;
          background-color: @primary-color;
        }
      }
      .btn-arrow-mask .btn-arrow {
        color: @text-color;
        background-color: transparent;
        margin: 0;
        box-shadow: none;
        &:hover,
        &:focus {
          border: none;
        }
      }
    }
  }
  .gradient-switch {
    height: 25px;
    display: flex;
    align-items: center;
    .ant-switch-inner {
      font-size: 14px;
    }
    .ant-switch-handle {
      top: 3.5px;
    }
  }
  .grouped-alignment {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    & > * {
      cursor: pointer;
    }
    .ant-space-item > .icon,
    .btn-distribute {
      padding: 4px;
      border-radius: @border-radius-small;
      &:hover {
        background-color: @menu-hover-color;
        transition: background-color 0.2s;
      }
    }
    .icon {
      font-size: 22px;
    }
    .btn-distribute {
      display: flex;
      align-items: center;
      .icon:last-child {
        font-size: 16px;
      }
    }
  }
}

.search-items-container {
  display: flex;
  gap: 6px;
  flex-flow: row wrap;
  margin-bottom: 10px;
}
.element-item {
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px solid @default-border-color;
  border-radius: 4px;
  width: 97px;
  height: 97px;
  &:hover {
    .element-item-actions {
      &-bg {
        background-color: rgba(0, 0, 0, 0.5);
      }
      svg {
        fill: rgba(255, 255, 255, 0.75);
      }
    }
  }
  &-img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 97px;
    height: 97px;
    object-fit: cover;
  }
  .element-item-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40px;
    font-size: 16px;
    &-bg {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 97px;
      height: 97px;
      transition: background-color 0.3s;
    }
    svg {
      fill: rgba(255, 255, 255, 0);
      transition: fill 0.3s;
    }
  }
}

.media-preview-modal {
  .ant-modal-header,
  .ant-modal-footer {
    padding: 16px;
    margin: 0;
  }
  .ant-modal-content {
    padding: 0;

    img,
    video {
      width: 100%;
    }
  }
}

.custom-fonts-list {
  margin-top: 16px;

  .custom-font-delete {
    cursor: pointer;
    margin-right: 2px;
  }
}

.interactivity-elements-controls {
  margin-top: 16px;

  .interaction-type-controls {
    margin-bottom: 16px;

    .interaction-card {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      color: @icon-dark-grey-color;
      padding: 10px;
      border-radius: 8px;
      border: 1px solid #dbdbdb;
      transition: color 0.3s, border 0.3s;

      .icon {
        font-size: 24px;

        &.transformed {
          transform: rotate(-45deg);
        }
      }

      .interaction-title {
        font-size: 12px;
        line-height: 140%;
      }

      &.active {
        color: @dark-color;
        border: 1px solid @primary-color;
      }
    }
  }
}


@import '../../../../style/variables.less';

.slides {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e8e8e8;
  padding-left: 10px;
  &-scrollbar {
    height: 100% !important;
  }
  .item {
    background-color: transparent;
    border: none;
    &-thumbnail {
      cursor: pointer;
      border: 1.5px solid @default-border-color;
      border-radius: @default-border-radius;
      overflow: hidden;
      &-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 182px;
        height: 102.4px;
        background-color: #fff;
        color: @text-color-secondary;
      }
    }
    .video-format-limiter {
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0px;
      transition: width 0.2s ease-in-out;
      border-radius: 8px 0 0 8px;
      &.right {
        right: 0;
        border-radius: 0 8px 8px 0;
      }
    }
    &-container {
      width: 95%;
      flex: 1 1 auto;
      &:first-child {
        margin-top: 16px;
      }
      .item-wrapper {
        display: flex;

        .slide-number-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          min-width: 16px;
          margin-right: 4px;

          .slide-number {
            text-align: right;
            display: block;
            font-weight: 700;
            color: @icon-dark-grey-color;
            line-height: 1rem;
          }

          .arrow-icon {
            color: @icon-dark-grey-color;
            font-size: 16px;
          }

          .btn-interactivity {
            display: flex;
            align-items: center;
            justify-content: center;
            color: @icon-dark-grey-color;
            font-size: 12px;
            background-color: @default-border-color-30;
            width: 16px;
            height: 16px;
            padding: 0;
            border: none;
            box-shadow: none;

            //remove button animation on click
            & > div {
              display: none;
            }

            &:hover {
              color: @dark-color;
            }

            &.slide {
              flex-direction: column;
              gap: 4px;
              height: 30px;
              padding: 2px;

              &-branch.active {
                color: #ffffff;
                background-color: @primary-color;
              }
            }

            &.link {
              .icon {
                transform: rotate(-45deg);
              }
            }

            .icon {
              margin: 0;
            }

            .interactivity-slide-number {
              font-size: 10px;
              font-weight: 700;
              line-height: 10px;
              height: 10px;
              padding-top: 2px;
            }
          }
        }

        .item-card-wrapper {
          position: relative;
        }
      }
      .item-btns-wrapper {
        opacity: 0;
        position: absolute;
        top: -4px;
        right: -10px;
        transition: opacity 0.3s;
      }
      &.active .item-btns-wrapper,
      .item:hover ~ .item-btns-wrapper,
      .item-btns-wrapper:hover {
        opacity: 1;
      }
      &.active {
        .item-thumbnail {
          border-color: @primary-color;
        }
        .item-wrapper .slide-number-wrapper .slide-number {
          color: @primary-color;
        }
      }
      &.waiting {
        cursor: wait !important;
        .item-thumbnail {
          cursor: wait !important;
        }
      }
      .transition {
        svg {
          cursor: pointer;
          margin: 4px auto;
          display: block;
        }
        &.active {
          svg {
            circle {
              stroke: @primary-color;
            }
            path {
              fill: @primary-color;
            }
            &:hover {
              circle {
                stroke: #3858ee;
              }
              path {
                fill: #3858ee;
              }
            }
          }
        }
        svg {
          &:hover {
            circle {
              stroke: @title-dark-grey-color;
            }
            path {
              fill: @title-dark-grey-color;
            }
          }
        }
      }
    }
    &-btn,
    &-btn > button:disabled {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: @border-radius-xsmall;
      border: none;
      box-shadow: 0px 0px 4px rgba(56, 51, 51, 0.15);
      &.copy {
        svg path {
          fill: transparent !important;
          stroke: #fff;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: #3858ee;
        }
        button:disabled {
          background-color: @icon-light-grey-color;
        }
      }
      &.delete {
        color: @icon-grey-color;
        svg path {
          fill: @icon-grey-color;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: @error-light-color;
          svg path {
            fill: @error-color;
          }
        }
      }
    }
    .ant-card-body {
      padding: 0;
    }
  }
  .btn-add {
    width: 185px;
    margin: 16px 30px 24px 20px;
  }
}

.interactions-popover {
  width: 240px;

  .ant-popover-inner {
    padding: 0;
    border-radius: @border-radius-middle;

    .popover-content-wrapper {
      padding: 8px;

      .btn-close {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 1rem;
        width: 12px;
        height: 12px;
        padding: 0;
        border-radius: @border-radius-xsmall;

        &-wrapper {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 4px;
        }
      }

      .popover-content {
        padding: 0 8px 16px;

        .description {
          line-height: 19.6px;
          margin-bottom: 24px;
        }

        .interactions-buttons-container {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 8px;

          .btn-interaction {
            display: flex;
            justify-content: center;
            align-items: center;
            color: @text-color;
            font-size: 12px;
            line-height: 16.8px;
            min-width: 24px;
            padding: 0;
            box-shadow: none;

            &:hover {
              color: #ffffff;
              background-color: @dark-color;
              border-color: @dark-color;
            }

            &.link {
              width: 24px;
              padding: 0;

              .icon {
                font-size: 14px;
                transform: rotate(-45deg);
              }
            }

            &:not(.end) {
              padding-top: ;
            }
          }
        }
      }
    }
  }
}

